





















































import { Vue, Component } from "vue-property-decorator";
import { productList } from "@/api/";
@Component({
    filters: {
        filterDate(e: String) {
            return e.replaceAll("、", "/");
        },
    },
})
export default class product extends Vue {
    public tabIndex = 0;

    public sortList = [
        {
            name: "当季新品",
            id: 1,
            enName: "New products",
        },
        {
            name: "套件类",
            id: 2,
            enName: "Bed Kit",
        },
        {
            name: "被芯类",
            id: 3,
            enName: "The quilt core",
        },
        {
            name: "床品搭配",
            id: 4,
            enName: "Bedding collocation",
        },
    ];

    public list = [];

    public handelJump(item: any, index: Number) {
        // let nextJson
        // if (Number(index) + 1 < this.list.length) {
        //     nextJson = this.list[Number(index) + 1]
        // }
        this.$router.push({ path: "/productDetail", query: { id: item.id } });
    }

    public handelTab(index: any, id: any) {
        this.tabIndex = index;
        this.getList(id);
    }

    public getList(productType: any) {
        productList({
            pageSize: 3,
            pageNum: 1,
            productType,
        }).then((res) => {
            this.list = res;
        });
    }

    public mounted() {
        this.getList(1);
    }

    public metaInfo = {
        title: "产品指南-舒适睡眠就是简单-简单睡眠",
        meta: [
            {
                name: "简单睡眠，简单睡眠家纺，床上用品，全棉四件套，磨毛四件套，保暖冬被，春秋被",
                content: "简单睡眠六大产品特色，全功能，重环保，高性价比，超舒适，新科技，多元化，努力让十亿国人享受舒适睡眠。",
            },
        ],
    };
}
